import ReactDOMServer from "react-dom/server"
import { generateJSON } from "@tiptap/html"
import { formatNoteText } from "@/util/format"

import { extensions } from "components/Editor/extensions"

import ReactMarkdown from "react-markdown"
import directive from "remark-directive"
import Banner from "remark/Banner"
import gfm from "remark-gfm"
import remarkUnwrapImages from "remark-unwrap-images"

const MarkdownRenderer = (markdown: string) => (
  <ReactMarkdown
    remarkPlugins={[directive, Banner, gfm, remarkUnwrapImages]}
    components={{
      ul: ({ node, className, ...props }) =>
        className === "contains-task-list" ? (
          <ul data-type="taskList" {...props} />
        ) : (
          <ul {...props} />
        ),
      li: ({ node, className, ...props }) =>
        className === "task-list-item" ? (
          <li
            data-type="taskItem"
            data-checked={`${props.checked}`}
            {...props}
          />
        ) : (
          <li {...props} />
        ),
      img: ({ node, title, ...props }) => {
        let floatClass = ""
        if (title === "left-50") floatClass = "img-float-left"
        else if (title === "right-50") floatClass = "img-float-right"
        return <img {...props} className={floatClass} />
      },
      // @ts-ignore
      info: ({ node, ...props }) => (
        <div data-type="callout" className="callout-info" {...props} />
      ),
      // @ts-ignore
      tip: ({ node, ...props }) => (
        <div data-type="callout" className="callout-tip" {...props} />
      ),
      // @ts-ignore
      warning: ({ node, ...props }) => (
        <div data-type="callout" className="callout-warning" {...props} />
      ),
      // @ts-ignore
      important: ({ node, ...props }) => (
        <div data-type="callout" className="callout-important" {...props} />
      ),
    }}
  >
    {markdown}
  </ReactMarkdown>
)

// https://regex101.com/r/MmRg1g/1
const imagesInHeaderRegex = /([#]+)\s*([^#!]*)\s*(!\[.*\].*\))([^\n]*)/g
// https://regex101.com/r/UhQa3h/1
const imagesInLinks = /\[\s*(\!\[.*)\](\(.*\))/g
// https://regex101.com/r/aeG5tH/1
const imagesWithTextRegex = /(!\[[^\]]*\]\([^\)]+\))([^\s])/g

/**
 * Given a piece of markdown text, render it.
 */
export const jsonFromMarkdownText = (text: string) => {
  if (!text) return

  // Move Image ($3) before Header ($1) & preserve possible before ($2) or after ($4) text
  let fixedText = text.replace(imagesInHeaderRegex, "$3\n\n$1 $2 $4")
  // Removes link ($2) from Image ($1) and places it under the image
  fixedText = fixedText.replace(imagesInLinks, "$1\n\n[Image Link]$2")
  // Add line breaks between Image ($1) and text ($2)
  fixedText = fixedText.replace(imagesWithTextRegex, "$1\n\n$2")
  // console.log("[FORMAT] Fixed Markdown: ", fixedText)
  const html = ReactDOMServer.renderToStaticMarkup(
    MarkdownRenderer(formatNoteText(fixedText))
  )
  // console.log("[FORMAT] Markdown -> HTML: ", html)
  const json = generateJSON(html, extensions)
  // console.log("[FORMAT] HTML -> JSON: ", JSON.stringify(json))
  return JSON.stringify(json)
}
