import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"
import { Combobox, Transition } from "@headlessui/react"
import clsx from "clsx"

import { tabs } from "components/Discover/DiscoverTags"
import { selectAllCategories, addCategoryToNote } from "features/noteSlice"
import useOutsideClick from "hooks/useOutsideClick"
import { useAppDispatch } from "store"

function Option({
  value,
  setCategoryText,
  setCurrentKeyDown,
  displayText,
}: {
  value: string
  setCategoryText: (categoryText: string) => void
  setCurrentKeyDown: (currentKeyDown: number) => void
  displayText?: string
}) {
  displayText ||= value

  return (
    <Combobox.Option
      key={value}
      value={value}
      onClick={() => {
        setCategoryText(value)

        // Simulate an enter
        setCurrentKeyDown(13)
      }}
      className={({ active }) =>
        clsx(
          "relative cursor-default select-none py-2 pl-3 pr-9",
          active ? "bg-blue-600 text-white" : "text-gray-900"
        )
      }
    >
      {({ active, selected }) => (
        <>
          <span className={clsx("block truncate", selected && "font-semibold")}>
            {displayText}
          </span>

          {selected && (
            <span
              className={clsx(
                "absolute inset-y-0 right-0 flex items-center pr-4",
                active ? "text-white" : "text-blue-600"
              )}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          )}
        </>
      )}
    </Combobox.Option>
  )
}

export default function AddTagInput(props: {
  existingCategories: Array<string>
  finishAdding: () => void
}) {
  const dispatch = useAppDispatch()
  const allCategories = useSelector(selectAllCategories)
  const ref = useRef(null)
  useOutsideClick(ref, () => props.finishAdding())

  const [categoryText, setCategoryText] = useState("")
  const [currentKeyDown, setCurrentKeyDown] = useState(-1)

  // We want to use everything except the "All" entry.
  const tabEntries = tabs.filter((tab) => tab.name !== "All")

  const filteredTabs =
    categoryText === ""
      ? tabEntries
      : tabEntries.filter((tab) => {
          return tab.name.toLowerCase().includes(categoryText.toLowerCase())
        })

  const filteredCategories =
    categoryText === ""
      ? Array.from(allCategories)
      : Array.from(allCategories).filter((cat) => {
          return cat.toLowerCase().includes(categoryText.toLowerCase())
        })

  useEffect(() => {
    console.log("Current key down!", currentKeyDown)
    // Escape
    if (currentKeyDown === 27) {
      setCategoryText("")
      props.finishAdding()
      return
    }

    // Enter
    if (currentKeyDown != 13) return

    // Don't allow empty entries
    if (!categoryText) {
      props.finishAdding()
      return
    }

    // Don't allow duplicate tags
    if (
      props.existingCategories.some(
        (c) => c.toLowerCase() === categoryText.toLowerCase()
      )
    ) {
      props.finishAdding()
      return
    }

    console.log("Dispatching category add!")
    dispatch(addCategoryToNote(categoryText.toLowerCase()))
    props.finishAdding()
  }, [currentKeyDown])

  return (
    <Combobox
      as="div"
      value={categoryText}
      onChange={(value: string) => setCategoryText(value)}
      ref={ref}
      className="z-50 relative inline-flex"
    >
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-1 pl-3 pr-10 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
          onChange={(event) => setCategoryText(event.target.value)}
          onKeyDown={(ev: any) => setCurrentKeyDown(ev.keyCode)}
          displayValue={() => categoryText}
          autoFocus
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {categoryText.length > 0 &&
              !filteredTabs.some(
                ({ name }) => name.toLowerCase() === categoryText.toLowerCase()
              ) && (
                <Option
                  setCurrentKeyDown={setCurrentKeyDown}
                  setCategoryText={setCategoryText}
                  value={categoryText}
                  displayText={`Create tag "${categoryText}"`}
                />
              )}
            {filteredCategories.length > 0 && (
              <div className="text-gray-500 px-3 py-4 text-xs ">Your tags</div>
            )}

            {filteredCategories?.map((cat) => (
              <Option
                key={cat}
                setCurrentKeyDown={setCurrentKeyDown}
                setCategoryText={setCategoryText}
                value={cat}
              />
            ))}

            {filteredTabs.length > 0 && (
              <div className="text-gray-500 px-3 py-4 text-xs ">
                Popular tags
              </div>
            )}
            {filteredTabs?.map((person) => (
              <Option
                setCurrentKeyDown={setCurrentKeyDown}
                setCategoryText={setCategoryText}
                key={person.name}
                value={person.name}
              />
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}
