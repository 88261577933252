import { Plugin, Transformer } from "unified"
import { visit } from "unist-util-visit"

const plugin: Plugin = () => {
  const transformer: Transformer = (tree) => {
    visit(
      tree,
      ["textDirective", "leafDirective", "containerDirective"],
      (node: any, position, parent) => {
        console.log("BOOO")
        console.log(node)
        console.log("NODE")
        console.log(node)

        node.data = {
          hName: node.name,
          hProperties: node.attributes,
          ...node.data,
        }

        return node
      }
    )
  }

  return transformer
}

export default plugin
