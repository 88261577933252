import React, { useState } from "react"
import { removeCategoryFromNote } from "../features/noteSlice"
import { useSelector } from "react-redux"
import { XMarkIcon, PlusIcon } from "@heroicons/react/24/solid"
import usePage from "../hooks/usePage"
import { CommunityEntry, Note } from "@types"
import useBlogUrl from "hooks/useBlogUrl"
import { selectBlog, selectUsersBlog } from "features/blogSlice"
import clsx from "clsx"

import AddTagInput from "components/AddTagInput"
import { useAppDispatch } from "store"
import { TimeFromNowText } from "./TimeFromNowText"

interface BadgeProps {
  color: string
  name: string
  showAddCategory: boolean
  clickable: boolean
  clickableBaseUrl?: string
}

function CategoryBadge(props: BadgeProps) {
  const dispatch = useAppDispatch()
  const router = usePage()
  const blog = useSelector(selectBlog)
  const { customDomainOrRelativeUrl } = useBlogUrl({ blog })

  const color = props.color || "gray"
  return (
    <span className="inline-flex my-2">
      <span
        onClick={(e) => {
          if (!props.clickable) return

          e.stopPropagation()
          e.preventDefault()
          e.nativeEvent.stopImmediatePropagation()

          const query = { ...router.query }

          console.log(
            "About to update path value for category from post list",
            query
          )

          const pathname = `${customDomainOrRelativeUrl}category/${props.name}`

          return router.push({ pathname })
        }}
        className={clsx(
          props.clickable ? `cursor-pointer hover:bg-${color}-200` : "",
          `inline-flex items-center py-0.5 pl-2 p-2 rounded-full text-xs font-medium bg-${color}-100 text-gray-700 mr-1 dark:bg-gray-700 dark:text-gray-200`
        )}
      >
        #{props.name}
        {props.showAddCategory && (
          <>
            <button
              onClick={(e) => {
                dispatch(removeCategoryFromNote(props.name))
              }}
              type="button"
              className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white"
            >
              <span className="sr-only">Remove category</span>
              <XMarkIcon />
            </button>
          </>
        )}
      </span>
    </span>
  )
}

function CommunityBadge(props: BadgeProps) {
  const router = usePage()
  const blog = useSelector(selectBlog)
  const { customDomainOrRelativeUrl } = useBlogUrl({ blog })

  const color = props.color || "gray"
  return (
    <span className="inline-flex my-2">
      <span
        onClick={(e) => {
          if (!props.clickable) return

          e.stopPropagation()
          e.preventDefault()
          e.nativeEvent.stopImmediatePropagation()

          const query = { ...router.query }

          console.log(
            "About to update path value for community from post list",
            query
          )

          const pathname = `${customDomainOrRelativeUrl}community/${props.name}`

          return router.push({ pathname })
        }}
        className={clsx(
          props.clickable ? `cursor-pointer hover:bg-${color}-200` : "",
          `inline-flex items-center py-0.5 pl-2 p-2 rounded-full text-xs font-medium bg-${color}-100 text-gray-700 mr-1 dark:bg-gray-700 dark:text-gray-200`
        )}
      >
        {props.name}
      </span>
    </span>
  )
}

interface CategoryBadgesProps {
  note: Note
  communityColor?: string
  categoryColor?: string
  showAddCategory: boolean
  showCategories: boolean
  clickableBaseUrl?: string
  clickable?: boolean
  communities?: Array<CommunityEntry>
  className?: string
}

function CategoryBadges(props: CategoryBadgesProps) {
  const blog = useSelector(selectUsersBlog)
  const [isAddingCategory, setIsAddingCategory] = useState(false)
  const router = usePage()

  const communityColor = props.communityColor || "gray"
  const categoryColor = props.categoryColor || "gray"

  let categoryBtn = (
    <button
      type="button"
      onClick={() => setIsAddingCategory(true)}
      className={`inline-flex items-center p-1 dark:bg-gray-700 dark:text-white text-${categoryColor}-700 bg-gray-100 border border-transparent rounded-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 hover:text-white my-2`}
    >
      <PlusIcon className="w-3 h-3" aria-hidden="true" />
    </button>
  )

  if (isAddingCategory) {
    categoryBtn = (
      <AddTagInput
        finishAdding={() => setIsAddingCategory(false)}
        existingCategories={props.note.categories || []}
      />
    )
  }

  const categories = props.note.categories || []
  const allCategoryBadges = categories.map((name: string, i: number) => {
    return (
      <CategoryBadge
        color={categoryColor}
        key={i}
        name={name}
        showAddCategory={props.showAddCategory}
        clickable={!!props.clickable}
        clickableBaseUrl={props.clickableBaseUrl}
      />
    )
  })

  const community = props.communities?.find(
    (c) => c.id === props.note?.communityId
  )

  const communityBadge = community && (
    <CommunityBadge
      color={communityColor}
      key={community.id}
      name={community.name}
      showAddCategory={false}
      clickable={!!props.clickable}
      clickableBaseUrl={props.clickableBaseUrl}
    />
  )

  const showScheduledBadge =
    !router.isPublic &&
    !props.note.published &&
    !props.note.archived &&
    props.note.scheduledAt &&
    props.note.isScheduled &&
    !props.note.draftOf

  const hasBadge =
    props.note.published || props.note.draftOf || showScheduledBadge

  const isPinnedPost =
    blog.pinnedPostIds?.includes(props.note.id) ||
    (props.note.draftOf && blog.pinnedPostIds?.includes(props.note.draftOf))

  return (
    <>
      <span
        className={clsx(
          props.className,
          "flex flex-row align-middle items-center h-full flex-wrap"
        )}
      >
        <div className="flex justify-between">
          <div className="flex align-middle justify-items-center items-center flex-wrap">
            {!router.isPublic &&
              (props.note.published ||
                (props.note.draftOf && !props.note.archived)) && (
                <span className="inline-flex my-2">
                  <span className="inline-flex items-center py-0.5 pl-2 pr-2 rounded-full text-xs font-medium bg-green-100 text-green-700 mr-1 dark:bg-green-300/90 dark:text-green-900">
                    Published
                  </span>
                </span>
              )}

            {!router.isPublic && props.note.draftOf && !props.note.archived && (
              <span className="inline-flex my-2">
                <span className="inline-flex items-center py-0.5 pl-2 pr-2 rounded-full text-xs font-medium bg-orange-100 text-orange-700 mr-1 dark:bg-orange-300 dark:text-orange-900">
                  Draft
                </span>
              </span>
            )}

            {/* Repeating check for `props.note.scheduledAt` here to make TypeScript happy. */}
            {showScheduledBadge && props.note.scheduledAt && (
              <span className="inline-flex my-2">
                <span className="inline-flex items-center py-0.5 pl-2 pr-2 rounded-full text-xs font-medium bg-blue-100 text-blue-700 mr-1 dark:bg-blue-200">
                  Scheduled
                  <TimeFromNowText unixTimeMS={props.note.scheduledAt} />
                </span>
              </span>
            )}

            {!router.isPublic && props.note.isUnlisted && !isPinnedPost && (
              <span className="inline-flex my-2">
                <span className="inline-flex items-center py-0.5 pl-2 pr-2 rounded-full text-xs font-medium bg-gray-100 text-gray-700 mr-1 dark:bg-gray-200">
                  Unlisted
                </span>
              </span>
            )}

            {!router.isPublic && props.note.isUnlisted && isPinnedPost && (
              <span className="inline-flex my-2">
                <span className="inline-flex items-center py-0.5 pl-2 pr-2 rounded-full text-xs font-medium bg-purple-100 text-purple-700 mr-1 dark:bg-purple-200/80 dark:text-purple-900">
                  Pinned
                </span>
              </span>
            )}

            {community && communityBadge}

            {props.showCategories && allCategoryBadges}

            {props.showAddCategory &&
              categories.length === 0 &&
              !hasBadge &&
              !isAddingCategory && (
                <span
                  onClick={() => setIsAddingCategory(true)}
                  className="text-gray-500 text-sm pr-3 cursor-pointer"
                >
                  Add a tag
                </span>
              )}

            {props.showAddCategory && categoryBtn}

            {/* DEBUG ONLY! COMMENT THIS OUT IN PROD. */}
            {/* {props.note.id && (
              <span className="inline-flex my-2">
                <span className="inline-flex items-center py-0.5 pl-2 pr-2 rounded-full text-xs font-medium bg-red-100 text-red-700 mr-1 dark:bg-red-200">
                  {props.note.id}
                </span>
              </span>
            )} */}
          </div>
        </div>
      </span>
    </>
  )
}

export default CategoryBadges
