import { NextRouter, useRouter } from "next/router"

interface Custom {
  isPublic: boolean
}

export default function usePage(): NextRouter & Custom {
  const router = useRouter()

  return {
    ...router,
    isPublic:
      router.pathname.includes("public") ||
      router.pathname.includes("discover") ||
      router.query.username != undefined ||
      router.query.blogname != undefined,
  }
}
