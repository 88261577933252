import clsx from "clsx"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

type Tab = {
  name: string
  url?: string
}

function DiscoverTab({ tab }: { tab: Tab }) {
  const router = useRouter()
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    const isCurrent =
      (tab.name === "All" && router.query?.tag === undefined) ||
      (tab.url && router.asPath.toLowerCase().endsWith(tab.url.toLowerCase()))

    setIsActive(isCurrent || false)
  }, [router.asPath, router.query.tag, tab.url, tab.name])

  const path = router.pathname.split("/")
  path.splice(-1)
  const basePath = path.join("/")

  //console.log("IS THIS CURRENT", isCurrent, tab.name, tab.url, router.asPath)

  return (
    <Link
      key={tab.name}
      href={`${basePath}/${tab.url || ""}`}
      className={clsx(
        isActive
          ? "bg-blue-100 text-blue-700"
          : "text-gray-500 hover:text-blue-700 hover:bg-blue-50",
        "px-3 py-2 font-medium text-sm rounded-md"
      )}
      aria-current={isActive ? "page" : undefined}
    >
      {tab.name}
    </Link>
  )
}

// These are used to 1) generate the sitemap-disc,
// and 2) to populate the category input box when a user begins typing (autocomplete).
export const tabs: Array<Tab> = [
  {
    name: "All",
  },
  {
    name: "Blockchain",
    url: "blockchain",
  },
  {
    name: "DeFi",
    url: "defi",
  },
  {
    name: "Cryptocurrency",
    url: "cryptocurrency,crypto",
  },
  {
    name: "Web3",
    url: "web3",
  },
  {
    name: "NFT",
    url: "nft,nfts",
  },
  {
    name: "GameFi",
    url: "gamefi,gaming",
  },
  {
    name: "Music",
    url: "music,nftmusic",
  },
  {
    name: "Metaverse",
    url: "metaverse",
  },
  {
    name: "AI",
    url: "ai",
  },
  {
    name: "Social",
    url: "social,web3social,farcaster,lens",
  },
  {
    name: "Security",
    url: "security,privacy",
  },
  {
    name: "Art",
    url: "art",
  },
  {
    name: "DAOs",
    url: "dao,daos",
  },
]

export default function DiscoverCategories() {
  return (
    <div>
      <div className="">
        <div className="">
          <nav
            className="-mb-px lg:justify-center flex space-x-8 pb-4 px-4"
            aria-label="Tabs"
          >
            <div>
              <div className="">
                <nav
                  className="flex space-x-4 whitespace-nowrap"
                  aria-label="Tabs"
                >
                  {tabs?.map((tab, idx) => (
                    <DiscoverTab key={JSON.stringify(tab) + idx} tab={tab} />
                  ))}
                </nav>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
