"use client"
import ReactTimeAgo from "components/TimeAgo"

// note?.scheduledAt
export function TimeFromNowText({
  unixTimeMS,
  noMargin = false,
}: {
  unixTimeMS: number
  noMargin?: boolean
}) {
  const scheduledForMoreThanOneDayAway =
    new Date(unixTimeMS).getTime() - new Date().getTime() > 86400000

  const formattedTime = new Date(unixTimeMS).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  })

  return (
    <span className={`${noMargin ? "" : "ml-1"}`}>
      <ReactTimeAgo date={unixTimeMS} locale="en-US" />
      {scheduledForMoreThanOneDayAway && <span>{` at ${formattedTime}`}</span>}
    </span>
  )
}
